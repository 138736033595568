import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/ctadisclosurebing.module.scss"
class CtadisclosureBing extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    let browser = null;
    if(isChrome()){
      browser = 'chrome';
    }
    else if(isFirefox()){
      browser = 'firefox';
    }
    else{
      browser = 'other';
    }
    return (
        <div id = {Styles.ctadisclaimerbing}>
          <p>By clicking the "Add to Firefox" button, I agree to installing the Easy Save extension and changing the Firefox Default Search Engine, and the Terms of Use and <a href="https://clearbar.app/terms-of-service/" target="_blank" className={Styles.bold} >Terms of Use</a> and <a href="https://clearbar.app/privacy-policy/" target="_blank" className={Styles.bold} >Privacy Policy</a>. The extension will update your search settings and will change your default search provider to Microsoft Bing.</p>
        </div>
    )
  }
}
export default CtadisclosureBing
