import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/pocket2col.module.scss"
import Data from "./data/pocketdata.json"
import FooterLinksSingle from "src/components/bingfooterlinks/footerlinks_single.js"


class pocket2col extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        pageData: props.data,
        data: Data
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }

      });
    }
  }
  render() {
    return (

      <div id = {Styles.pocket2col}>
      <div  className = {Styles.page}>

        <div className = {Styles.inner}>
            <div className = {Styles.left}>
              <div className = {Styles.bulletpoints}>
                <div className = {Styles.arrow1}></div><span dangerouslySetInnerHTML={{ __html: this.state.data.step1}} className = {Styles.bp}></span>
                <div className = {Styles.arrow2}></div><span dangerouslySetInnerHTML={{ __html: this.state.data.step2}} className = {Styles.bp}></span>
                <div className = {Styles.arrow3}></div><span dangerouslySetInnerHTML={{ __html: this.state.data.step3}} className = {Styles.bp}></span>
              </div>
            </div>

            <div className = {Styles.right}>
            <img src={this.state.data.logo} className={Styles.logo}/>
            <h1 className="hc_param">{this.state.data.headline}</h1>
            <h3 className={Styles.subhead}>{this.state.data.subhead}</h3>

            <button className = {Styles.install} data-cy="cta" onClick = {() => window.triggerInstall()}
              dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>
            {this.props.children}
            </div>
        </div>

        <FooterLinksSingle></FooterLinksSingle>
      </div>
</div>
    )
  }
}
export default pocket2col
