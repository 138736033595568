import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Pocket2col from "src/components/pocket2col"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtadisclosureBing from "src/components/ctadisclosureBing"
import CtadisclosureSimple from "src/components/ctadisclosureSimple"

const pocketData = import("./data/data.json");

export default function prdbest() {

  let disclosure = null;
  if(isChrome()){
    disclosure = <CtadisclosureSimple  data={pocketData}></CtadisclosureSimple>;
  }
  else{
    disclosure = <CtadisclosureBing data={pocketData}></CtadisclosureBing>;
  }

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`

        #pocket2col-module--pocket2col .pocket2col-module--arrow3 {
            display: none;
          }

        .footerlinkssingle-module--br {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        #ctadisclosurebing-module--ctadisclaimerbing, #ctadisclosurebing-module--ctadisclaimerbing a {
          color: #666;
        }
    `}
    </style>

    <title>Save any Webpage Easily - EasySave.app</title></Helmet>
    <section>
      <Pocket2col data={pocketData}>
      {disclosure}
    </Pocket2col>
      </section>
    </HomepageLayout>
  )
}
